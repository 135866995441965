import React from "react";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { NOOP } from "libs/utils";
import { BsFillStarFill } from "react-icons/bs";
import { FiRefreshCcw, FiPlay } from "react-icons/fi";
import ProgressBar from "@ramonak/react-progress-bar";

const ResultPage = ({
  results,
  quizId,
  onComplete,
  onUpdateScores,
  onResetScores,
}) => {
  const { numberOfCorrectAnswers, numberOfQuestions } = results;

  const Stars = () => {
    const starsCount = Array(numberOfQuestions)
      .fill()
      .map((e, i) => i + 1);
    return starsCount.map((item) => {
      const iconColor = numberOfCorrectAnswers < item ? "gray" : "orange";
      return (
        <div
          key={item}
          className={iconColor === "orange" ? "animate-bounce" : ""}
        >
          <BsFillStarFill color={iconColor} className="m-2" />
        </div>
      );
    });
  };

  return (
    <div className="whitespace-wrap rounded-md h-fit w-full bg-white">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2 mt-10 mb-4 flex w-full justify-between">
          <div className="w-full">
            <ProgressBar
              className="w-full"
              completed={(numberOfCorrectAnswers * 100) / numberOfQuestions}
            />
          </div>
          <span
            className="whitespace-nowrap"
            color="gray-600"
            size="14"
            font="bold"
          >
            {`${numberOfCorrectAnswers} / ${numberOfQuestions}`}
          </span>
        </div>
        <div className="flex items-center w-full justify-center mb-2">
          <Stars />
        </div>
      </div>
      <div className="flex justify-between">
        <Button
          variant="filled"
          bgColor="#6051bb"
          borderRadius="24px"
          onClick={() => onResetScores(quizId)}
        >
          <FiRefreshCcw color="white" />
          <span color="white" className="text-white" size="14" font="normal">
            RETRY
          </span>
        </Button>
        <Button
          variant="filled"
          bgColor="#6051bb"
          borderRadius="24px"
          onClick={() => {
            onUpdateScores({
              [quizId]: {
                numberOfCorrectAnswers,
                numberOfQuestions,
              },
            });
            onComplete();
          }}
        >
          <span color="white" className="text-white" size="14" font="normal">
            CONTINUE
          </span>
          <FiPlay color="white" />
        </Button>
      </div>
    </div>
  );
};

ResultPage.propTypes = {
  results: PropTypes.object.isRequired,
  onComplete: PropTypes.func,
  quizId: PropTypes.string,
  onUpdateScores: PropTypes.func,
  onResetScores: PropTypes.func,
};

ResultPage.defaultProps = {
  onComplete: NOOP,
  onUpdateScores: NOOP,
  onResetScores: NOOP,
};

export default ResultPage;
