import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEvents,
  selectIsLoading,
  selectLastEvent,
  selectEventCount,
} from "redux-toolkit/events/selectors";
import {
  getEventsAsync,
  getNextEventsAsync,
} from "redux-toolkit/events/actions";

const useEvents = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const events = useSelector(selectEvents);
  const isLoading = useSelector(selectIsLoading);
  const last = useSelector(selectLastEvent);
  const count = useSelector(selectEventCount);

  useEffect(() => {
    dispatch(getEventsAsync());
  }, [dispatch]);

  const getNextPage = () => {
    if (last && count > events.length) {
      dispatch(getNextEventsAsync(last));
      setPage(page + 1);
    }
  };

  return {
    events,
    isLoading,
    hasMoreItems: count > events.length,
    page,
    setPage,
    getNextPage,
  };
};

export default useEvents;
