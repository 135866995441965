import React from "react";
import UsersTable from "./UsersTable";

const GroupMembers = ({
  allUsers,
  members,
  errors,
  setFieldValue = () => {},
}) => {
  const addMember = (user) => {
    const member = members.findIndex((member) => member.id === user.id);
    if (member !== -1) {
      setFieldValue("members", [
        ...members.filter((member) => member.id !== user.id),
        user,
      ]);
    } else {
      setFieldValue("members", [...members, user]);
    }
  };
  return (
    <div className="flex flex-col items-center w-full px-20">
      <div className="w-full flex flex-col gap-2">
        <UsersTable
          rowData={members}
          allUsers={allUsers}
          addMember={addMember}
          isEditable={true}
        />
      </div>
    </div>
  );
};

export default GroupMembers;
