import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Card from "components/card/Card";
import { Button } from "@mui/material";

const PresentationList = ({ presentations }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="w-full h-full overflow-y-auto p-16"
      style={{
        background: "#e2e2e2",
      }}
    >
      <div className="m-auto">
        <div className="w-full grid justify-items-end">
          <Button
            variant="outlined"
            onClick={() => navigate("/presentations/manage")}
            className="justify-items-end"
          >
            {t("presentations.presentationList.createNewPresentation")}
          </Button>
        </div>
        <div className="p-8 grid grid-cols-4 gap-4 w-full">
          {presentations.map(({ title, images, goal, id }) => {
            return (
              <Card
                title={title}
                image={images?.cover || images?.poster}
                path={`/presentations/${id}`}
                description={goal}
                primaryButtonText={t(
                  "presentations.presentationList.card.view",
                )}
                secondaryButtonText={t(
                  "presentations.presentationList.card.edit",
                )}
                onSecondaryButtonClick={() =>
                  navigate(`/presentations/manage/${id}`)
                }
                onPrimaryButtonClick={() =>
                  window.open(`/presentations/${id}`, "_blank")
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PresentationList;
