import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Typography,
  Box,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import { groupRoles } from "constants/groups";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const AddMemberModal = ({
  currentMember = {},
  open,
  handleClose,
  allUsers = [],
  addMember = () => {},
}) => {
  const { t } = useTranslation();
  const [user, setUser] = useState(currentMember);
  const memberOptions = useMemo(() => {
    return allUsers.map(({ firstName, lastName, id }) => ({
      label: `${firstName} ${lastName}`,
      firstName,
      lastName,
      id,
    }));
  }, [allUsers]);

  const currentSelectedUser = useMemo(() => {
    return memberOptions.filter((option) => option.id === user?.id);
  }, [user, memberOptions]);

  const roleOptions = useMemo(
    () =>
      Object.values(groupRoles).map((role) => ({
        label: t(role.label),
        role: role.role,
      })),
    [t],
  );

  const currentSelectedRole = useMemo(() => {
    return roleOptions.filter((option) => option.role === user?.role);
  }, [user, roleOptions]);

  return (
    <Modal
      style={{ background: `rgb(22 28 36 / 70%)`, backdropFilter: `blur(8px)` }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t("groups.groupMembers.addMember.title")}
        </Typography>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={currentSelectedUser[0]}
          options={memberOptions}
          onChange={(e, value) =>
            setUser({
              ...user,
              firstName: value?.firstName,
              lastName: value?.lastName,
              id: value?.id,
            })
          }
          sx={{ width: 300, marginTop: 4 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("groups.groupMembers.addMember.members")}
            />
          )}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={currentSelectedRole[0]}
          options={roleOptions}
          sx={{ width: 300, marginTop: 4 }}
          onChange={(e, value) => setUser({ ...user, role: value.role })}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("groups.groupMembers.addMember.role")}
            />
          )}
        />

        <TextField
          size="small"
          value={user?.task}
          onChange={(e) => setUser({ ...user, task: e.target.value })}
          fullWidth
          label={t("groups.groupMembers.addMember.task")}
          sx={{ marginTop: 4 }}
          // error={Boolean(touched.email && errors.email)}
          // helperText={touched.email && errors.email}
        />
        <div className="flex justify-start gap-2 mt-4">
          <Button variant="text" onClick={handleClose}>
            {t("groups.groupMembers.addMember.cancel")}
          </Button>
          <Button
            variant="outlined"
            disabled={!user?.id || !user?.role}
            onClick={() => {
              !!user && !!user?.id && !!user?.role && addMember(user);
              handleClose();
            }}
          >
            {t("groups.groupMembers.addMember.add")}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AddMemberModal;
