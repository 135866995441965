import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentEvent,
  selectIsLoading,
} from "redux-toolkit/events/selectors";
import {
  getEventByIdAsync,
  createEventAsync,
  updateEventAsync,
  deleteEventAsync,
} from "redux-toolkit/events/actions";

const useEvent = ({ id }) => {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentEvent);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    id && dispatch(getEventByIdAsync(id));
  }, [dispatch, id]);

  const createEvent = useCallback(
    (newArticle) => {
      dispatch(createEventAsync(newArticle));
    },
    [dispatch],
  );

  const updateEvent = useCallback(
    (id, data) => {
      dispatch(updateEventAsync({ id, data }));
    },
    [dispatch],
  );

  const deleteEvent = useCallback(
    (id) => {
      dispatch(deleteEventAsync(id));
    },
    [dispatch],
  );

  return {
    current,
    isLoading,
    createEvent,
    updateEvent,
    deleteEvent,
  };
};

export default useEvent;
