import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectGroups, selectIsLoading } from "redux-toolkit/groups/selectors";
import { getGroupsAsync } from "redux-toolkit/groups/actions";

const useGroups = () => {
  const dispatch = useDispatch();
  const groups = useSelector(selectGroups);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    dispatch(getGroupsAsync());
  }, [dispatch]);

  return {
    groups,
    isLoading,
  };
};

export default useGroups;
