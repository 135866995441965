import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  limit,
  addDoc,
  updateDoc,
  deleteDoc,
  orderBy,
  startAfter,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "config/firebase";

const getDocument = (id) => {
  return doc(db, "lessons", id);
};

export const getData = async (reference) => {
  if (reference) {
    const docSnap = await getDoc(reference);
    return docSnap.exists ? { id: docSnap?.id, ...docSnap.data() } : null;
  }
  return null;
};

// export const getLessons = async () => {
//   const q = query(collection(db, "lessons"), limit(12));
//   const querySnapshot = await getDocs(q);
//   const lessons = querySnapshot.docs.map((doc) => {
//     return { ...doc.data(), category: [], reviewers: [], id: doc.id };
//   });
//   return lessons;
// };

export const getLessons = async (last) => {
  try {
    // Query to get the lessons
    const q = last
      ? query(
          collection(db, "lessons"),
          orderBy("createdAt", "desc"),
          startAfter(last),
          limit(10)
        )
      : query(
          collection(db, "lessons"),
          orderBy("createdAt", "desc"),
          limit(10)
        );

    const documentSnapshots = await getDocs(q);

    // Get the last visible document
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];

    const lessons = documentSnapshots.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });

    // Get the total count of lessons in the collection
    const countQuery = query(collection(db, "lessons"));
    const countSnapshot = await getCountFromServer(countQuery);
    const totalLessonsCount = countSnapshot.data().count;
    // Return lessons, last document, and total count of lessons
    return { lessons, last: lastVisible, totalLessonsCount };
  } catch (error) {
    console.error(`Error while getting lessons: ${error}`);
    return error;
  }
};

export const getLessonById = async (id) => {
  const docRef = getDocument(id);
  const docSnap = await getDoc(docRef);
  const lesson = docSnap.data();
  return docSnap.exists
    ? { id: docSnap.id, ...lesson, category: [], reviewers: [] }
    : null;
};

export const createLesson = async (lesson) => {
  try {
    await addDoc(collection(db, "lessons"), lesson);
    return lesson;
  } catch (e) {
    console.error(`Error while creating lesson ${e}`);
    return false;
  }
};

export const updateLessons = async (id, data) => {
  try {
    await updateDoc(getDocument(id), data);
  } catch (e) {
    console.error(`Error while updating lesson ${e}`);
    return false;
  }
};

export const deleteLesson = async (id) => {
  try {
    await deleteDoc(getDocument(id));
  } catch (e) {
    console.error(`Error while deleting lesson ${e}`);
    return false;
  }
};
