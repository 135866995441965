import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectArticlesLength,
  selectIsLoading,
  selectPresentationsLength,
  selectGroupsLength,
} from "redux-toolkit/dashboard/selectors";
import {
  getArticlesLengthAsync,
  getPresentationsLengthAsync,
  getGroupsLengthAsync,
} from "redux-toolkit/dashboard/actions";

const useDashboard = () => {
  const dispatch = useDispatch();
  const articlesLength = useSelector(selectArticlesLength);
  const isLoading = useSelector(selectIsLoading);
  const presentationsLength = useSelector(selectPresentationsLength);
  const groupsLength = useSelector(selectGroupsLength);

  useEffect(() => {
    dispatch(getArticlesLengthAsync());
    dispatch(getPresentationsLengthAsync());
    dispatch(getGroupsLengthAsync());
  }, [dispatch]);

  return {
    articlesLength,
    presentationsLength,
    groupsLength,
    isLoading,
  };
};

export default useDashboard;
