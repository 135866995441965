import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Card from "components/card/Card";
import { Button } from "@mui/material";

const GroupList = ({ groups }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="w-full h-full overflow-y-auto p-16"
      style={{
        background: "#e2e2e2",
      }}
    >
      <div className="m-auto">
        <div className="w-full grid justify-items-end">
          <Button
            variant="outlined"
            onClick={() => navigate("/groups/manage")}
            className="justify-items-end"
          >
            {t("groups.groupList.createNewGroup")}
          </Button>
        </div>
        <div className="p-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
          {groups.map(({ title, images, goal, id }) => {
            return (
              <Card
                title={title}
                image={images?.cover || images?.poster}
                path={`/groups/${id}`}
                description={goal}
                primaryButtonText={t("groups.groupList.card.view")}
                secondaryButtonText={t("groups.groupList.card.edit")}
                onSecondaryButtonClick={() => navigate(`/groups/manage/${id}`)}
                onPrimaryButtonClick={() => navigate(`/groups/${id}`)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GroupList;
