import React from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import ImageUploader from "components/image-upload/ImageUploader";

const GroupOverview = ({ overview, errors, setFieldValue = () => {} }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-8 items-center w-full px-20">
      <div className="flex-1">
        <div className="flex gap-10">
          <ImageUploader
            text={t("groups.groupOverview.uploadCover")}
            image={overview?.images?.cover || ""}
            setImage={(file, url) =>
              setFieldValue("images", {
                ...overview.images,
                cover: file,
              })
            }
          />
          <ImageUploader
            text={t("groups.groupOverview.uploadPoster")}
            image={overview?.images?.poster || ""}
            setImage={(file, url) =>
              setFieldValue("images", {
                ...overview.images,
                poster: file,
              })
            }
          />
        </div>
      </div>
      <div className="w-1/2 flex flex-col gap-2">
        <TextField
          size="small"
          value={overview?.title || ""}
          onChange={(e) => setFieldValue("title", e.target.value)}
          fullWidth
          label={t("groups.groupOverview.title")}
          // error={Boolean(touched.email && errors.email)}
          // helperText={touched.email && errors.email}
        />
        <TextField
          size="small"
          value={overview?.goal || ""}
          onChange={(e) => setFieldValue("goal", e.target.value)}
          fullWidth
          label={t("groups.groupOverview.goal")}
          // error={Boolean(touched.email && errors.email)}
          // helperText={touched.email && errors.email}
        />
      </div>
    </div>
  );
};

export default GroupOverview;
