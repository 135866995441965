import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLessons,
  selectIsLoading,
  selectLastLesson,
  selectLessonsCount,
} from "redux-toolkit/lessons/selectors";
import { getLessonsAsync, getNextLessonsAsync } from "redux-toolkit/lessons/actions";

const useLessons = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const lessons = useSelector(selectLessons);
  const isLoading = useSelector(selectIsLoading);
  const last = useSelector(selectLastLesson);
  const count = useSelector(selectLessonsCount);

  useEffect(() => {
    dispatch(getLessonsAsync());
  }, [dispatch]);

  const getNextPage = () => {
    if (last && count > lessons.length) {
      dispatch(getNextLessonsAsync(last));
      setPage(page + 1);
    }
  };

  return {
    lessons,
    isLoading,
    hasMoreItems: count > lessons.length,
    page,
    setPage,
    getNextPage,

  };
};

export default useLessons;
