import { useFormik } from "formik";
import { articleFormSchema } from "libs/validation/schemas";
import mediaUploader from "api/mediaUploader.api";
import useAuth from "hooks/useAuth";
import { CACHE_SIZE_UNLIMITED } from "firebase/firestore";

const FIELDS = {
  TITLE: "title",
  DESCRIPTION: "description",
  IMAGES: "images",
  CONTENT: "content",
  CACHE_SIZE_UNLIMITED,
  CATEGORIES: "categories",
};

const useArticleForm = (props) => {
  const { article, create, update, id } = props;
  const { currentUser } = useAuth();
  const articleForm = useFormik({
    initialValues: {
      [FIELDS.TITLE]: article.title || "",
      [FIELDS.IMAGES]: article.images || {},
      [FIELDS.DESCRIPTION]: article.description || "",
      [FIELDS.CONTENT]: article.content || "",
      [FIELDS.CATEGORIES]: article.categories || [],
    },
    enableReinitialize: true,
    validationSchema: articleFormSchema,
    onSubmit: async (values) => {
      let thumbnailUrl = "";
      let coverUrl = "";
      let contentUrl = "";
      const slug = `${values[FIELDS.TITLE].toLowerCase().replaceAll(" ", "-")}`;
      if (typeof values[FIELDS.IMAGES]?.cover !== "string") {
        coverUrl = await mediaUploader(
          values[FIELDS.IMAGES]?.cover,
          `articles/covers/${slug}`,
        );
      }
      if (typeof values[FIELDS.IMAGES]?.thumbnail !== "string") {
        thumbnailUrl = await mediaUploader(
          values[FIELDS.IMAGES]?.thumbnail,
          `articles/thumbnails/${slug}`,
        );
      }

      if (typeof values[FIELDS.CONTENT]) {
        const blob = new Blob([values[FIELDS.CONTENT]], { type: "text/plain" });
        const file = new File([blob], `${slug}.txt`, { type: "text/plain" });
        contentUrl = await mediaUploader(file, `articles/content/${slug}`);
      }

      create({
        ...values,
        images: { thumbnail: thumbnailUrl, cover: coverUrl },
        createdAt: Date.now(),
        content: contentUrl,
        author: { id: currentUser?.id, displayName: currentUser.displayName },
      });
      update(id, {
        ...values,
        images: {
          thumbnail: thumbnailUrl || article.images.thumbnail,
          cover: coverUrl || article.images.cover,
        },
        content: contentUrl,
        updatedAt: Date.now(),
      });
    },
  });

  const { values, setFieldValue, handleSubmit, resetForm } = articleForm;

  return {
    articleForm,
    FIELDS,
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    errors: articleForm.errors,
  };
};

export default useArticleForm;
