import { createSlice } from "@reduxjs/toolkit";
import {
  getLessonsAsync,
  getLessonByIdAsync,
  getNextLessonsAsync,
} from "./actions";

const initialState = {
  isLoading: true,
  current: null,
  all: [],
  last: null,
  count: 0,
};

export const lessonsSlice = createSlice({
  name: "lessons",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLessonsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getLessonsAsync.fulfilled, (state, action) => {
        state.all = action.payload.lessons;
        state.last = action.payload.last;
        state.count = action.payload.totalLessonsCount;
        state.isLoading = false;
      })
      .addCase(getLessonsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getNextLessonsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getNextLessonsAsync.fulfilled, (state, action) => {
        state.all = [...state.all, ...action.payload.lessons];
        state.last = action.payload.last;
        state.isLoading = false;
      })
      .addCase(getNextLessonsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getLessonByIdAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getLessonByIdAsync.fulfilled, (state, action) => {
        state.current = action.payload;
        state.isLoading = false;
      })
      .addCase(getLessonByIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.current = null;
      });
  },
});

export default lessonsSlice.reducer;
