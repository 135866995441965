import React from "react";
import { useTranslation } from "react-i18next";
import lions from "assets/lions.jpeg";
import { Typography } from "@mui/material";
import UserTable from "./UsersTable";

const GroupView = ({ group }) => {
  const { t } = useTranslation();
  const poster = group?.images?.poster ? group?.images?.poster : lions;
  return (
    <div className="h-full bg-cover bg-center mb-40">
      <div
        className="w-full h-52 bg-black bg-opacity-50 "
        style={{ backgroundImage: `url(${poster})` }}
      />
      <div className="w-full h-24 bg-white flex flex-row">
        <Typography
          className="truncate text-center w-3/4 my-auto"
          gutterBottom
          variant="h2"
          component="div"
        >
          {group?.title}
        </Typography>
        <img
          alt="group cover"
          src={group?.images?.cover}
          className="-my-16 mx-auto rounded-full h-40 w-40"
        />
      </div>
      <div className="overflow-y-visible mb-24">
        <Typography
          className="text-center w-3/4 mx-auto"
          gutterBottom
          variant="h4"
          component="div"
        >
          {group?.goal}
        </Typography>
        <Typography
          className="truncate text-center w-full mx-auto my-8"
          gutterBottom
          variant="h3"
          component="div"
        >
          {t("groups.groupView.memberList")}
        </Typography>
        <div className="w-3/5 mx-auto ">
          <UserTable rowData={group?.members} />
        </div>
      </div>
    </div>
  );
};

export default GroupView;
