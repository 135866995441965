import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import shortid from "shortid";
import TextEditor from "components/textEditor/TextEditor";
import DraggableList from "components/draggable/DraggableList";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const PresentationOverview = ({ slides, errors, setFieldValue = () => {} }) => {
  const { t } = useTranslation();
  const [contents, setContents] = useState({});

  // TODO: made optimizations and bugfix
  useEffect(() => {
    const newSlides = slides?.map((item) => {
      return { ...item, content: contents[item.id] };
    });
    setFieldValue(newSlides);
  }, [contents]);
  return (
    <div className="w-full px-20 h-screen">
      <DraggableList handleStateChange={setFieldValue} items={slides} />
      <div className="flex items-center justify-center w-full mt-4">
        <IconButton
          onClick={() => {
            const slideID = `item-${shortid.generate()}`;
            setFieldValue([
              ...slides,
              {
                id: slideID,
                className: "slide",
                content: [<q></q>],
                element: (
                  <TextEditor
                    value={"<q>Slide</q>"}
                    setValue={(value) => {
                      setContents({ ...contents, [slideID]: value });
                    }}
                    isEditable
                  />
                ),
              },
            ]);
          }}
          edge="end"
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default PresentationOverview;
