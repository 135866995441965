import React from "react";
import { Button } from "@mui/material";
import { FaCheck } from "react-icons/fa";
import { RiCloseFill } from "react-icons/ri";
import { GrFormNext } from "react-icons/gr";
import useQuiz from "./useQuiz";
import ResultPage from "./ResultPage";

const Quiz = ({ quiz, onComplete, onUpdateScores }) => {
  const {
    clicked,
    onAnswerClick,
    correctAnswers,
    currentQuestion,
    numberOfQuestions,
    currentQuestionNumber,
    onNextClick,
    numberOfCorrectAnswers,
    onResetClick,
  } = useQuiz({ quizData: quiz });

  const lengthOfCorrectAnswers = correctAnswers.length;

  return (
    <div className="flex w-full justify-center">
      <div className="whitespace-wrap align-middle rounded-md w-[600px] bg-white p-4">
        <div className="flex flex-row mb-8 mt-4 font-bold text-right">
          {currentQuestionNumber <= numberOfQuestions && (
            <h3>
              Հարց {currentQuestionNumber} / {numberOfQuestions}
            </h3>
          )}
        </div>
        {currentQuestionNumber <= numberOfQuestions ? (
          <div>
            <span className="text-md font-normal">
              {currentQuestion?.question}
            </span>
            <div className="flex flex-row my-2">
              <div className="bg-blue-800 mr-4 p-1 text-white text-xs rounded-lg">
                {lengthOfCorrectAnswers > 1
                  ? "Բազմապատասխան ընտրություն"
                  : "Մեկ ընտրություն"}
              </div>
              <div className="bg-purple-800 p-1 text-white text-xs rounded-lg">
                {`Ընտրել ${lengthOfCorrectAnswers} պատասխան`}
              </div>
            </div>
            {currentQuestion?.answers?.map((item, index) => (
              <div key={index}>
                <button
                  disabled={
                    clicked.includes(index + 1) ||
                    clicked.length >= correctAnswers.length
                  }
                  className={`my-1 p-2 font-bold px-4 w-full text-left shadow-md ${
                    clicked.includes(index + 1)
                      ? correctAnswers.includes(`${index + 1}`)
                        ? "bg-green-200"
                        : "bg-red-200"
                      : "bg-gray-300"
                  } ${
                    clicked.includes(index + 1)
                      ? correctAnswers.includes(`${index + 1}`)
                        ? "scale-105"
                        : "scale-95"
                      : "scale-100"
                  } ${
                    !clicked.includes(index + 1) && "hover:bg-gray-100"
                  } "text-black" transition-all duration-1000`}
                  onClick={() => {
                    onAnswerClick(index);
                  }}
                >
                  <div className="flex justify-between items-center">
                    <span className="font-normal">{item}</span>
                    {clicked.includes(index + 1) &&
                      (correctAnswers.includes(`${index + 1}`) ? (
                        <FaCheck color="green" />
                      ) : (
                        <RiCloseFill size={28} color="red" />
                      ))}
                  </div>
                </button>
              </div>
            ))}
            <div
              className="font-bold w-full text-left flex flex-row-reverse"
              style={{
                visibility:
                  clicked.length < correctAnswers.length ? "hidden" : "visible",
              }}
            >
              <Button
                onClick={onNextClick}
                // disabled={clicked.length <= correctAnswers.length}
              >
                <GrFormNext size={30} />
              </Button>
            </div>
          </div>
        ) : (
          <ResultPage
            results={{ numberOfQuestions, numberOfCorrectAnswers }}
            onResetScores={onResetClick}
            onComplete={onComplete}
            onUpdateScores={onUpdateScores}
            quizId={quiz.id}
          />
        )}
      </div>
    </div>
  );
};

export default Quiz;
