import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Animate } from "react-animate-mount";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Stepper from "components/stepper/Stepper";
import EventOverview from "components/events/EventOverview";
import useEvent from "hooks/events/useEvent";
import useEventForm from "hooks/events/useEventForm";
import ArticleView from "components/articles/ArticleView";
import LocationAutocomplete from "components/google-maps-input/LocationAutocomplete";
import TopicsForm from "components/events/TopicsForm";

const ManageEvent = ({ isEditable }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const { current, updateEvent, createEvent } = useEvent({ id });
  const { errors, FIELDS, values, setFieldValue, handleSubmit } = useEventForm({
    id,
    event: current || {},
    create: !id ? createEvent : () => {},
    update: id ? updateEvent : () => {},
  });
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const steps = [
    {
      id: 1,
      title: "Step 1",
      desc: t("articles.general"),
      content: (
        <div className="pb-20">
          <EventOverview
            errors={{
              title: errors[FIELDS.TITLE],
              description: errors[FIELDS.DESCRIPTION],
              image: errors[FIELDS.IMAGE],
            }}
            overview={{
              title: values[FIELDS.TITLE],
              description: values[FIELDS.DESCRIPTION],
              image: values[FIELDS.IMAGE],
              organizers: values[FIELDS.ORGANIZERS],
              start: values[FIELDS.START],
              end: values[FIELDS.END],
            }}
            setFieldValue={setFieldValue}
          />
        </div>
      ),
    },
    {
      id: 2,
      title: "Step 2",
      desc: t("events.topics"),
      content: (
        <div className="pb-20">
          <TopicsForm
            currentTopics={values[FIELDS.TOPICS]}
            setCurrentTopics={(value) => setFieldValue(FIELDS.TOPICS, value)}
          />
        </div>
      ),
    },
    {
      id: 3,
      title: "Step 3",
      desc: t("events.map"),
      content: (
        <div className="pb-20">
          <LocationAutocomplete
            location={values[FIELDS.LOCATION]}
            mapPosition={values[FIELDS.MAP]}
            setLocation={(value) => {
              setFieldValue(FIELDS.LOCATION, value);
            }}
            setMapPosition={(value) => {
              setFieldValue(FIELDS.MAP, value);
            }}
          />
        </div>
      ),
    },
    {
      id: 4,
      title: "Step 3",
      desc: t("articles.review"),
      content: <ArticleView isEditable={isEditable} article={values} />,
    },
    {
      id: 5,
      title: "Step 3",
      desc: t("articles.publish"),
      content: <div>Հրապարակված է</div>,
    },
  ];

  return (
    <div className="mt-10 flex flex-col justify-center">
      <Animate show={isMounted}>
        <Stepper
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          isWithErrors={Object.keys(errors).length}
        />
      </Animate>
      <div className="flex w-full justify-between mt-10">
        {activeStep < steps.length - 1 && (
          <div
            onClick={() => activeStep > 0 && setActiveStep(activeStep - 1)}
            className={`p-2 bg-opacity-50 z-50 flex items-center ${
              activeStep > 0 ? "opacity-100 cursor-pointer" : "opacity-0"
            }`}
          >
            <FaChevronLeft size={32} color="gray" />
          </div>
        )}
        <div className="w-full">
          {steps.map((step, index) => {
            return (
              <Animate show={index === activeStep} key={index}>
                {step.content}
              </Animate>
            );
          })}
        </div>
        {activeStep <= 3 &&
          !Object.keys(errors).length &&
          values[FIELDS.TITLE] && (
            <div
              onClick={() => {
                if (activeStep === 2) {
                  setActiveStep(activeStep + 1);
                  handleSubmit();
                } else {
                  setActiveStep(activeStep + 1);
                }
              }}
              className={`p-2 bg-opacity-50 cursor-pointer flex justify-end items-center ${
                steps.length !== activeStep ? "opacity-100" : "opacity-0"
              }`}
            >
              <FaChevronRight size={32} color="gray" />
            </div>
          )}
      </div>
    </div>
  );
};

export default ManageEvent;
