const truncate = (str, maxLength = 115) => {
  if (str?.length <= maxLength) {
    return str;
  }
  const subString = str?.substr(0, maxLength - 1) || "";
  return subString.substr(0, subString.lastIndexOf(" ")) + " ...";
};

const NOOP = () => {};

const getGradientClasses = (color) => {
  return `bg-gradient-to-t from-${color}-500 to-${color}-700`;
};

const hasKey = (object, key) => {
  return Object.prototype.hasOwnProperty.call(object, key);
};

const encodeJsonToBase64 = (val) => {
  return window.btoa(JSON.stringify(val));
};

const decodeBase64ToJson = (encoded) => {
  return JSON.parse(window.atob(encoded));
};

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const fillRequiredFiled = (obj, key, defaultVal) => {
  const updatedObj = { ...obj };
  if (!updatedObj.hasOwnProperty(key)) {
    updatedObj[key] = defaultVal;
  }
  return updatedObj;
};

const unique = (value, index, self) => {
  return self.indexOf(value) === index && value != null;
};

export {
  NOOP,
  truncate,
  getGradientClasses,
  getBase64,
  hasKey,
  encodeJsonToBase64,
  decodeBase64ToJson,
  fillRequiredFiled,
  unique,
};
