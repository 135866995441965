import React from "react";
import { useParams } from "react-router-dom";
import useLesson from "hooks/lessons/useLesson";
import LessonView from "components/lessons/LessonView";

const Lesson = ({ isEditable }) => {
  const { id } = useParams();
  const { current, isLoading } = useLesson({ id });
  return (
    <div>
      {isLoading ? (
        <>Loading...</>
      ) : (
        <LessonView lesson={current} isEditable={isEditable} />
      )}
    </div>
  );
};

export default Lesson;
