import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Button, TextField } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import useEditTopics from "./useEditTopics";

const TopicsForm = (props) => {
  const { currentTopics, setCurrentTopics } = props;
  const { t } = useTranslation();
  const { newTopics } = useEditTopics({
    currentTopics,
  });
  return (
    <div className="w-full flex items-center justify-center bg-gray-100 group">
      <div className="h-full w-full bg-gray-100 p-4">
        <div className="flex justify-between border-b pb-4 mb-4">
          <span size="16" font="normal">
            {t("events.topic.topics")}
          </span>
        </div>
        {currentTopics?.topics?.map((item, topicIndex) => {
          return (
            <div
              key={topicIndex}
              className="flex flex-col mt-4 border-b-4 border-color-black pb-8"
            >
              <div className="flex items-center mt-4">
                <span className="text-lg font-bold">
                  {t("events.topic.topic")} {topicIndex + 1}
                </span>
                <div className="opacity-0 group-hover:opacity-100">
                  <Button
                    onClick={() => {
                      newTopics.splice(topicIndex, 1);
                      setCurrentTopics({
                        ...currentTopics,
                        topics: newTopics,
                      });
                    }}
                  >
                    <MdDelete color="red" size={20} />
                  </Button>
                </div>
              </div>
              <div className="flex">
                <div className="w-full">
                  <TextField
                    fullWidth
                    defaultValue={item.topic}
                    onChange={(e) => {
                      newTopics[topicIndex].topic = e.target.value;
                      setCurrentTopics({
                        ...currentTopics,
                        topics: newTopics,
                      });
                    }}
                    placeholder={`${t("events.topic.topic")} ${topicIndex + 1}`}
                  />
                </div>
              </div>
              <div className="w-fit">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  sx={{ marginTop: "8px", marginBottom: "8px" }}
                >
                  <DemoContainer components={["DateTimePicker"]}>
                    <DateTimePicker
                      label="Սկիզբ"
                      value={
                        newTopics[topicIndex].start
                          ? dayjs(newTopics[topicIndex].start)
                          : null
                      }
                      onChange={(newValue) => {
                        newTopics[topicIndex].start = new Date(
                          newValue,
                        ).toISOString();
                        setCurrentTopics({
                          ...currentTopics,
                          topics: newTopics,
                        });
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              {currentTopics.topics[topicIndex].speakers.map(
                (item, speakerIndex) => {
                  const handleOnChange = (value) => {
                    newTopics[topicIndex].speakers[speakerIndex] = value;
                    setCurrentTopics({ ...currentTopics, topics: newTopics });
                  };
                  return (
                    <div
                      key={speakerIndex}
                      className="flex justify-between items-center mt-6 ml-16"
                    >
                      <div className="w-full">
                        <TextField
                          fullWidth
                          value={
                            currentTopics.topics[topicIndex].speakers[
                              speakerIndex
                            ]
                          }
                          onChange={(e) => {
                            handleOnChange(e.target.value);
                          }}
                          placeholder={`${t("events.topic.speaker")} ${
                            speakerIndex + 1
                          }`}
                        />
                      </div>
                      <div className="opacity-0 group-hover:opacity-100">
                        <Button
                          onClick={() => {
                            newTopics[topicIndex].speakers.splice(
                              speakerIndex,
                              1,
                            );
                            setCurrentTopics({
                              ...currentTopics,
                              topics: newTopics,
                            });
                          }}
                        >
                          <MdDelete color="red" size={20} />
                        </Button>
                      </div>
                    </div>
                  );
                },
              )}
              <div className="flex justify-center mt-4">
                <Button
                  onClick={() => {
                    newTopics[topicIndex].speakers.push("");
                    setCurrentTopics({ ...currentTopics, topics: newTopics });
                  }}
                >
                  <IoMdAdd size={20} />
                  {t("events.topic.addSpeaker")}
                </Button>
              </div>
              <div className="w-full">
                <TextField
                  fullWidth
                  value={currentTopics.topics[topicIndex].summary}
                  onChange={(e) => {
                    const value = e.target.value;
                    newTopics[topicIndex].summary = value;
                    setCurrentTopics({ ...currentTopics, topics: newTopics });
                  }}
                  placeholder={t("events.topic.summary")}
                />
              </div>
            </div>
          );
        })}
        <div className="flex justify-center mt-8">
          <Button
            onClick={() => {
              newTopics.push({
                speakers: [],
              });
              setCurrentTopics({ ...currentTopics, topics: newTopics });
            }}
          >
            <IoMdAdd size={20} />
            {t("events.topic.addTopic")}
          </Button>
        </div>
      </div>
    </div>
  );
};

TopicsForm.propTypes = {
  setCurrentTopics: PropTypes.func.isRequired,
};

export default TopicsForm;
