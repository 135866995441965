import { createSlice } from "@reduxjs/toolkit";
import { getGroupsAsync, getGroupByIdAsync } from "./actions";

const initialState = {
  isLoading: true,
  current: null,
  all: [],
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGroupsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getGroupsAsync.fulfilled, (state, action) => {
        state.all = action.payload;
        state.isLoading = false;
      })
      .addCase(getGroupsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getGroupByIdAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getGroupByIdAsync.fulfilled, (state, action) => {
        state.current = action.payload;
        state.isLoading = false;
      })
      .addCase(getGroupByIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.current = null;
      });
  },
});

export default groupsSlice.reducer;
