const useEditSummary = ({ currentSummary }) => {
  const newPoints = currentSummary?.points?.map((item) => ({
    ...item,
    subPoints: item.subPoints.map((item) => item),
    point: item.point,
  }));

  return {
    newPoints,
  };
};

export default useEditSummary;
