import React, { useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ImageUploader from "components/image-upload/ImageUploader";
import {
  TextField,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { MdDelete } from "react-icons/md";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const EventOverview = ({ overview, errors, setFieldValue }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim()) {
      setFieldValue("organizers", [...overview?.organizers, inputValue]);
      setInputValue(""); // Clear the input field
    }
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  return (
    <div className="flex flex-col gap-8 items-center w-full px-20">
      <div className="w-3/4 flex flex-col gap-2">
        <TextField
          size="small"
          value={overview?.title || ""}
          onChange={(e) => setFieldValue("title", e.target.value)}
          fullWidth
          label={t("articles.title")}
          error={Boolean(errors?.title)}
          helperText={t(errors?.title)}
        />
        <TextField
          size="small"
          value={overview?.description || ""}
          onChange={(e) => setFieldValue("description", e.target.value)}
          fullWidth
          label={t("articles.desc")}
          error={Boolean(errors?.description)}
          helperText={t(errors?.description)}
        />
        <div>
          <div className="flex items-center mt-4">
            <span className="text-lg font-bold">{t("events.organizers")}</span>
          </div>
          <List>
            {overview?.organizers.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item} />
                <Button
                  onClick={() => {
                    setFieldValue(
                      "organizers",
                      overview.organizers.filter((_, i) => i !== index),
                    );
                  }}
                >
                  <MdDelete color="red" size={20} />
                </Button>
              </ListItem>
            ))}
          </List>
          <TextField
            label="Ավելացնել կազմակերպիչ"
            value={inputValue}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            fullWidth
          />
        </div>
      </div>
      <div className="w-fit">
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          sx={{ marginTop: "8px", marginBottom: "8px" }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "48px", // spacing between the pickers
              marginTop: "8px",
              marginBottom: "8px",
            }}
          >
            <DateTimePicker
              label="Սկիզբ"
              value={overview?.start ? dayjs(overview.start) : null}
              maxDate={overview?.end ? dayjs(overview.end) : null}
              onChange={(newValue) => {
                setFieldValue("start", new Date(newValue).toISOString());
              }}
            />
            <DateTimePicker
              label="Ավարտ"
              value={overview?.end ? dayjs(overview.end) : null}
              minDate={overview?.start ? dayjs(overview.start) : null}
              onChange={(newValue) => {
                setFieldValue("end", new Date(newValue).toISOString());
              }}
            />
          </Box>
        </LocalizationProvider>
      </div>
      <div className="flex v1/2 gap-10">
        <ImageUploader
          text={t("articles.thumbnail")}
          image={overview?.image || ""}
          setImage={(file, url) => setFieldValue("image", file)}
        />
      </div>
    </div>
  );
};

export default EventOverview;
