import { createSlice } from "@reduxjs/toolkit";
import {
  getEventsAsync,
  getEventByIdAsync,
  getNextEventsAsync,
} from "./actions";

const initialState = {
  isLoading: true,
  current: null,
  all: [],
  last: null,
  count: 0,
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEventsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getEventsAsync.fulfilled, (state, action) => {
        state.all = action.payload.events;
        state.last = action.payload.last;
        state.count = action.payload.totalEventsCount;
        state.isLoading = false;
      })
      .addCase(getEventsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getNextEventsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getNextEventsAsync.fulfilled, (state, action) => {
        state.all = [...state.all, ...action.payload.Events];
        state.last = action.payload.last;
        state.isLoading = false;
      })
      .addCase(getNextEventsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getEventByIdAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getEventByIdAsync.fulfilled, (state, action) => {
        state.current = action.payload;
        state.isLoading = false;
      })
      .addCase(getEventByIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.current = null;
      });
  },
});

export default eventsSlice.reducer;
