import React from "react";
import LessonList from "components/lessons/LessonList";
import useLessons from "hooks/lessons/useLessons";
import Loading from "components/common/Loading";
import useAuth from "hooks/useAuth";

const Articles = () => {
  const { lessons, isLoading, getNextPage, page, setPage, hasMoreItems } =
    useLessons();
  const { currentUser } = useAuth();
  return isLoading && lessons.length === 0 ? (
    <Loading />
  ) : (
    <LessonList
      lessons={lessons}
      currentUserId={currentUser?.id}
      currentUserType={currentUser?.type}
      isLoadingNextPage={isLoading}
      hasMoreItems={hasMoreItems}
      getNextPage={getNextPage}
      page={page}
      setPage={setPage}
    />
  );
};

export default Articles;
