import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import TextEditor from "components/textEditor/TextEditor";
import ImageUploader from "components/image-upload/ImageUploader";
import Quiz from "components/quiz/Quiz";
import LessonSummary from "components/lessons/summary/LessonSummary";
import "../../../node_modules/video-react/dist/video-react.css"; // import css

const LessonView = ({ lesson, isEditable }) => {
  const { t } = useTranslation();
  const coverUrl =
    lesson?.images?.cover &&
    (typeof lesson?.images?.cover === "string"
      ? lesson?.images?.cover
      : URL.createObjectURL(lesson?.images?.cover));
  const posterUrl =
    lesson?.images?.poster &&
    (typeof lesson?.images?.poster === "string"
      ? lesson?.images?.poster
      : URL.createObjectURL(lesson?.images?.poster));
  return (
    <div className="h-full bg-cover bg-center mb-40 group">
      {posterUrl && (
        <div className="relative">
          <ImageUploader
            isDisabled={!isEditable}
            imageComponent={
              <div
                className="relative w-full h-64 bg-black bg-opacity-50 hover:bg-gray-800"
                style={{ backgroundImage: `url(${posterUrl})` }}
              />
            }
            text={t("lessons.cover")}
            image={lesson?.images?.poster || ""}
            setImage={(file, url) => {}}
          />
          {coverUrl && (
            <div className="absolute right-20 -bottom-20">
              <ImageUploader
                isDisabled={!isEditable}
                imageComponent={
                  <img
                    alt="group cover"
                    src={coverUrl}
                    className="rounded-md h-40 w-40"
                  />
                }
                text={t("lessons.poster")}
                image={coverUrl}
                setImage={(file, url) => {}}
              />
              <Typography
                className="mt-4"
                gutterBottom
                variant="text"
                component="div"
                color="textSecondary"
              >
                {lesson?.subject}
              </Typography>
            </div>
          )}
        </div>
      )}
      <div className="px-10">
        <div className="w-full h-24 bg-white flex flex-row">
          <div className="w-3/4 my-auto">
            <Typography gutterBottom variant="h3" component="div">
              {lesson?.title}
            </Typography>
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab disabled={!lesson?.description}>Ընդհանուր</Tab>
            <Tab disabled={!lesson?.content}>Բովանդակություն</Tab>
            <Tab disabled={!lesson?.video}>Տեասադաս</Tab>
            <Tab disabled={!lesson?.summary}>Ամփոփում</Tab>
            <Tab disabled={!lesson?.quiz}>Հարցում</Tab>
          </TabList>
          <TabPanel>
            <div className="overflow-y-visible mb-24 mt-20">
              <Typography
                className="text-center w-3/4 mx-auto"
                gutterBottom
                variant="h4"
                component="div"
              >
                {lesson?.description}
              </Typography>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="w-full">
              {lesson?.content && (
                <TextEditor
                  isEditable={isEditable}
                  value={lesson?.content}
                  height="100%"
                  width="100%"
                />
              )}
            </div>
          </TabPanel>
          <TabPanel>
            <div
              className="p-16"
              dangerouslySetInnerHTML={{ __html: lesson?.video }}
            />
          </TabPanel>
          <TabPanel>
            {lesson?.summary && <LessonSummary summary={lesson.summary} />}
          </TabPanel>
          <TabPanel>{lesson?.quiz && <Quiz quiz={lesson.quiz} />}</TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default LessonView;
