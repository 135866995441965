import { useEffect, useMemo, useState } from "react";

const useQuiz = ({ quizData }) => {
  const [quiz, setQuiz] = useState({});

  const [clicked, setClicked] = useState([]);
  const [index, setIndex] = useState(0);
  const [numberOfIncorrectAnswers, setNumberOfIncorrectAnswers] = useState(0);

  const correctAnswers = useMemo(() => {
    if (quiz?.questions === undefined) {
      return [];
    }
    return Array.isArray(quiz?.questions[index]?.correctAnswer)
      ? quiz?.questions[index].correctAnswer
      : [quiz?.questions[index]?.correctAnswer] || [];
  }, [index, quiz]);

  const numberOfQuestions = quiz?.questions?.length || 0;

  const currentQuestion = useMemo(() => {
    if (quiz?.questions === undefined) {
      return {};
    }
    return quiz?.questions[index];
  }, [quiz?.questions, index]);

  const currentQuestionNumber = index + 1;

  const onNextClick = () => {
    setIndex(index + 1);
    for (let i = 0; i < clicked.length; i++) {
      if (!correctAnswers.includes(`${clicked[i]}`)) {
        setNumberOfIncorrectAnswers(numberOfIncorrectAnswers + 1);
        break;
      }
    }
    setClicked([]);
  };

  const onAnswerClick = (i) => {
    setClicked([...clicked, i + 1]);
  };

  const onResetClick = () => {
    setIndex(0);
    setClicked([]);
    setNumberOfIncorrectAnswers(0);
  };

  useEffect(
    () => {
      setQuiz(quizData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [quiz],
  );

  return {
    correctAnswers,
    clicked,
    onAnswerClick,
    numberOfQuestions,
    currentQuestion,
    currentQuestionNumber,
    numberOfCorrectAnswers: numberOfQuestions - numberOfIncorrectAnswers,
    onNextClick,
    onResetClick,
  };
};

export default useQuiz;
