import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDo43UbiEl_Iaki8931QHWA9bQ5XJCVDPs",
  authDomain: "armtrain-cd7c6.firebaseapp.com",
  projectId: "armtrain-cd7c6",
  storageBucket: "armtrain-cd7c6.appspot.com",
  messagingSenderId: "416418785597",
  appId: "1:416418785597:web:e09c6128d0543a498b3d54",
  measurementId: "G-CVRYWTW19J",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
