const googleMapsConfig = {
  dev: "AIzaSyDU2ImPq6WloWIPJw0BGL6iyRTiWlQBbPk",
  prod: "AIzaSyDU2ImPq6WloWIPJw0BGL6iyRTiWlQBbPk", // TODO add googleMapsApiKey for prod
};

const id = "google-map-script";
const libraries = ["places"];
const googleMapsApiKey = googleMapsConfig["dev"];

export { id, googleMapsApiKey, libraries };
