import React from "react";
import useGroups from "hooks/groups/useGroups";
import GroupList from "components/groups/GroupList";
import Loading from "components/common/Loading";

const Groups = () => {
  const { groups, isLoading } = useGroups();
  return isLoading ? <Loading /> : <GroupList groups={groups} />;
};

export default Groups;
