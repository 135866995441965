import React from "react";
import useGroup from "hooks/groups/useGroup";
import { useParams } from "react-router-dom";
import GroupView from "components/groups/GroupView";

const Group = () => {
  const { id } = useParams();
  const { current, isLoading } = useGroup({ id });
  return isLoading ? <div>loading...</div> : <GroupView group={current} />;
};

export default Group;
