import { createSlice } from "@reduxjs/toolkit";
import { getPresentationsAsync, getPresentationByIdAsync } from "./actions";

const initialState = {
  isLoading: true,
  current: null,
  all: [],
};

export const presentationsSlice = createSlice({
  name: "presentations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPresentationsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPresentationsAsync.fulfilled, (state, action) => {
        state.all = action.payload;
        state.isLoading = false;
      })
      .addCase(getPresentationsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getPresentationByIdAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPresentationByIdAsync.fulfilled, (state, action) => {
        state.current = action.payload;
        state.isLoading = false;
      })
      .addCase(getPresentationByIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.current = null;
      });
  },
});

export default presentationsSlice.reducer;
