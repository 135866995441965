import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getArticles,
  createArticle,
  updateArticles,
  deleteArticle,
  getArticleById,
} from "api/articles.api";

export const getArticlesAsync = createAsyncThunk(
  "articles/get-all",
  async () => {
    const data = await getArticles();
    return data;
  },
);

export const getNextArticlesAsync = createAsyncThunk(
  "articles/get-all-next",
  async (last) => {
    const data = await getArticles(last);
    return data;
  },
);

export const getArticleByIdAsync = createAsyncThunk(
  "article/get-by-id",
  async (id) => {
    const article = await getArticleById(id);
    const response = await fetch(article.content);
    const text = await response.text();
    return { ...article, content: text };
  },
);

export const createArticleAsync = createAsyncThunk(
  "articles/create",
  async (article) => {
    const articles = await createArticle(article);
    return articles;
  },
);

export const updateArticleAsync = createAsyncThunk(
  "article/update",
  async ({ id, data }) => {
    await updateArticles(id, data);
  },
);

export const deleteArticleAsync = createAsyncThunk(
  "article/delete",
  async (id) => {
    await deleteArticle(id);
  },
);
