import { useState, useCallback } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

const useGoogleMaps = (setLocation, setMapPosition) => {
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const {
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = useCallback(
    (value) => {
      setLocation(value);
      setValue(value);
    },
    [setLocation, setValue],
  );

  const handleSelect = useCallback(
    async ({ description }) => {
      setValue(description, false);
      setLocation(description);
      clearSuggestions();

      const results = await getGeocode({ address: description });
      const { lat, lng } = getLatLng(results[0]);
      setSelected({ lat, lng });
      setMapPosition({ lat, lng });
    },
    [setValue, setLocation, clearSuggestions, setMapPosition],
  );

  const handleOnMapClick = useCallback(
    async (e) => {
      setMapPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      setSelected({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      const results = await getGeocode({
        location: { lat: e.latLng.lat(), lng: e.latLng.lng() },
      });
      setValue(results[0].formatted_address, false);
      setLocation(results[0].formatted_address);
      clearSuggestions();
      setIsOpen(false);
    },
    [setSelected, setValue, setLocation, clearSuggestions, setMapPosition],
  );

  return {
    selected,
    isOpen,
    status,
    data,
    ref,
    handleInput,
    handleSelect,
    handleOnMapClick,
    setIsOpen,
  };
};

export default useGoogleMaps;
