import React from "react";
import usePresentations from "hooks/presentations/usePresentations";
import Loading from "components/common/Loading";
import PresentationList from "components/presentations/PresentationList";

const Presentations = () => {
  const { presentations, isLoading } = usePresentations();
  return isLoading ? (
    <Loading />
  ) : (
    <PresentationList presentations={presentations} />
  );
};

export default Presentations;
