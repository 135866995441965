import { useTranslation } from "react-i18next";
import { useLoadScript } from "@react-google-maps/api";
import LocationInput from "./ui-elements/LocationInput";
import { id, googleMapsApiKey, libraries } from "config/google-maps-config";

const LocationAutocomplete = ({
  location,
  setLocation,
  setMapPosition,
  mapPosition,
}) => {
  const { isLoaded } = useLoadScript({
    id,
    googleMapsApiKey,
    libraries,
  });
  const { t } = useTranslation();

  return isLoaded ? (
    <div className="flex flex-col gap-8 items-center w-full px-20">
      <LocationInput
        mapPosition={mapPosition}
        location={location}
        setLocation={setLocation}
        setMapPosition={setMapPosition}
      />
    </div>
  ) : (
    <>{t("map.loading")}</>
  );
};
export default LocationAutocomplete;
