import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUsers, selectIsLoading } from "redux-toolkit/users/selectors";
import { getUsersAsync } from "redux-toolkit/users/actions";

const useUsers = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    dispatch(getUsersAsync());
  }, [dispatch]);

  return {
    users,
    isLoading,
  };
};

export default useUsers;
