import React from "react";
import { Impress, Step } from "react-impressjs";
import TextEditor from "components/textEditor/TextEditor";
import "pages/articles/test.css";

const PresentationView = ({ data }) => {
  //TODO: made bugfix
  const newData =
    data.slides &&
    data.slides?.map((item) => ({
      ...item,
      content: [
        <TextEditor
          isEditable={false}
          value={item}
          height="600px"
          width="100%"
        />,
      ],
    }));
  return (
    <div className="">
      {data.slides && (
        <Impress progress={true}>
          {newData?.map((d, index) => {
            return React.createElement(
              Step,
              {
                id: d?.id,
                className: d?.className,
                data: { x: index * 1000, y: -1500 },
                key: index,
              },
              d?.content.map((child, index) => {
                return React.cloneElement(child, {
                  id: child.id,
                  className: child.className,
                  key: index,
                });
              }),
            );
          })}
        </Impress>
      )}
    </div>
  );
};

export default PresentationView;
