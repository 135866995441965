import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getLessons,
  createLesson,
  updateLessons,
  deleteLesson,
  getLessonById,
} from "api/lessons.api";

export const getLessonsAsync = createAsyncThunk("lessons/get-all", async () => {
  const data = await getLessons();
  return data;
});

export const getNextLessonsAsync = createAsyncThunk(
  "lessons/get-all-next",
  async (last) => {
    const data = await getLessons(last);
    return data;
  }
);

export const getLessonByIdAsync = createAsyncThunk(
  "lesson/get-by-id",
  async (id) => {
    const lesson = await getLessonById(id);
    const response = await fetch(lesson.content);
    const text = await response.text();
    return { ...lesson, content: text };
  }
);

export const createLessonAsync = createAsyncThunk(
  "lessons/create",
  async (lesson) => {
    const lessons = await createLesson(lesson);
    return lessons;
  }
);

export const updateLessonAsync = createAsyncThunk(
  "lesson/update",
  async ({ id, data }) => {
    await updateLessons(id, data);
  }
);

export const deleteLessonAsync = createAsyncThunk(
  "lesson/delete",
  async (id) => {
    await deleteLesson(id);
  }
);
