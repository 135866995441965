import { useFormik } from "formik";
import { groupFormSchema } from "libs/validation/schemas";
import mediaUploader from "api/mediaUploader.api";

const FIELDS = {
  TITLE: "title",
  GOAL: "goal",
  IMAGES: "images",
  CONTENT: "content",
  MEMBERS: "members",
  OWNER: "owner",
};

const useGroupForm = (props) => {
  const { group, create, update, id } = props;
  const groupForm = useFormik({
    initialValues: {
      [FIELDS.TITLE]: group?.title || "",
      [FIELDS.IMAGES]: group?.images || {},
      [FIELDS.GOAL]: group?.goal || "",
      [FIELDS.CONTENT]: group?.content || "",
      [FIELDS.MEMBERS]: group?.members || [],
      [FIELDS.OWNER]: group?.owner || "",
    },
    enableReinitialize: true,
    validationSchema: groupFormSchema,
    onSubmit: async (values) => {
      let posterUrl = "";
      let coverUrl = "";
      const slug = `${values[FIELDS.TITLE].toLowerCase().replaceAll(" ", "-")}`;
      if (typeof values[FIELDS.IMAGES]?.cover !== "string") {
        coverUrl = await mediaUploader(
          values[FIELDS.IMAGES]?.cover,
          `groups/covers/${slug}`,
        );
      }
      if (typeof values[FIELDS.IMAGES]?.poster !== "string") {
        posterUrl = await mediaUploader(
          values[FIELDS.IMAGES]?.poster,
          `groups/posters/${slug}`,
        );
      }
      create({
        ...values,
        images: { poster: posterUrl, cover: coverUrl },
        createdAt: Date.now(),
      });
      update(id, {
        ...values,
        images: {
          poster: posterUrl || group.images.poster,
          cover: coverUrl || group.images.cover,
        },
        updatedAt: Date.now(),
      });
    },
  });

  const { values, setFieldValue, handleSubmit, resetForm } = groupForm;

  return {
    groupForm,
    FIELDS,
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
  };
};

export default useGroupForm;
