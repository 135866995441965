import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";
import Button from "@mui/material/Button";
import AddMemberModal from "./AddMemberModal";
import { groupRoles } from "constants/groups";

const getRowId = (row) => row.id;

const UserTable = ({
  rowData = [],
  allUsers,
  addMember,
  isEditable = false,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentMember, setCurrentMember] = useState({});
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        name: "firstName",
        title: t("groups.groupMembers.userTable.firstName"),
      },
      { name: "lastName", title: t("groups.groupMembers.userTable.lastName") },
      { name: "roleLabel", title: t("groups.groupMembers.userTable.role") },
      { name: "task", title: t("groups.groupMembers.userTable.task") },
    ],
    [t],
  );
  const [rows, setRows] = useState(
    rowData.map((row, index) => ({
      firstName: row.firstName,
      lastName: row.lastName,
      task: row.task,
      roleLabel: t(groupRoles[row.role].label),
      role: row.role,
      id: row?.id,
    })) || [],
  );

  const updateMembers = (user) => {
    const member = rows.findIndex((member) => member.id === user.id);
    if (member !== -1) {
      setRows([
        ...rows.filter((member) => member.id !== user.id),
        {
          firstName: user.firstName,
          lastName: user.lastName,
          task: user.task,
          roleLabel: t(groupRoles[user.role].label),
          role: user.role,
          id: user?.id,
        },
      ]);
    } else {
      setRows([
        ...rows,
        {
          firstName: user.firstName,
          lastName: user.lastName,
          task: user.task,
          roleLabel: t(groupRoles[user.role].label),
          role: user.role,
          id: user?.id,
        },
      ]);
    }
  };

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      // eslint-disable-next-line no-alert
      onClick={() => {
        if (isEditable) {
          setCurrentMember(row);
          setOpenModal(true);
        }
      }}
      style={{
        cursor: "pointer",
      }}
    />
  );

  return (
    <Paper>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <Table rowComponent={TableRow} />
        <TableHeaderRow />
      </Grid>
      {isEditable && (
        <div className="w-full p-4">
          <Button
            className="w-full test-center"
            variant="outlined"
            onClick={() => {
              setCurrentMember({});
              setOpenModal(true);
            }}
          >
            {t("groups.groupMembers.newMember")}
          </Button>
        </div>
      )}
      {useMemo(() => {
        return (
          openModal &&
          isEditable && (
            <AddMemberModal
              currentMember={currentMember}
              open={openModal}
              handleClose={() => setOpenModal(false)}
              allUsers={allUsers}
              addMember={(user) => {
                addMember(user);
                updateMembers(user);
              }}
            />
          )
        );
      }, [openModal, allUsers])}
    </Paper>
  );
};

export default UserTable;
