import React from "react";
import PropTypes from "prop-types";

import { GoogleMap, Marker } from "@react-google-maps/api";
import { NOOP } from "libs/utils";

const MapsModal = (props) => {
  const { selectedLocation, onMapClick } = props;

  return (
    <div className="">
      <GoogleMap
        center={
          selectedLocation || {
            lat: 40.280080986820295,
            lng: 44.55223814814053,
          }
        }
        onClick={onMapClick}
        mapContainerStyle={{
          width: "400px",
          height: "400px",
        }}
        zoom={8}
      >
        {selectedLocation && <Marker position={selectedLocation} />}
      </GoogleMap>
    </div>
  );
};

MapsModal.propTypes = {
  onClose: PropTypes.func,
  onMapClick: PropTypes.func,
  isOpen: PropTypes.bool,
  selectedLocation: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

MapsModal.defaultProps = {
  onClose: NOOP,
  onMapClick: NOOP,
  isOpen: false,
  selectedLocation: { lat: 37.6, lng: -95.665 },
};

export default MapsModal;
