import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPresentations,
  selectIsLoading,
} from "redux-toolkit/presentations/selectors";
import { getPresentationsAsync } from "redux-toolkit/presentations/actions";

const usePresentations = () => {
  const dispatch = useDispatch();
  const presentations = useSelector(selectPresentations);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    dispatch(getPresentationsAsync());
  }, [dispatch]);

  return {
    presentations,
    isLoading,
  };
};

export default usePresentations;
