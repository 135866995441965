import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentPresentation,
  selectIsLoading,
} from "redux-toolkit/presentations/selectors";
import {
  getPresentationByIdAsync,
  createPresentationAsync,
  updatePresentationAsync,
  deletePresentationAsync,
} from "redux-toolkit/presentations/actions";

const usePresentation = ({ id }) => {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentPresentation);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    id && dispatch(getPresentationByIdAsync(id));
  }, [dispatch, id]);

  const createPresentation = useCallback(
    (newItem) => {
      dispatch(createPresentationAsync(newItem));
    },
    [dispatch],
  );

  const updatePresentation = useCallback(
    (id, data) => {
      dispatch(updatePresentationAsync({ id, data }));
    },
    [dispatch],
  );

  const deletePresentation = useCallback(
    (id) => {
      dispatch(deletePresentationAsync(id));
    },
    [dispatch],
  );

  return {
    current,
    isLoading,
    createPresentation,
    updatePresentation,
    deletePresentation,
  };
};

export default usePresentation;
