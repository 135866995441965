import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  limit,
  addDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "config/firebase";

const getDocument = (id) => {
  return doc(db, "groups", id);
};

export const getData = async (reference) => {
  if (reference) {
    const docSnap = await getDoc(reference);
    return docSnap.exists ? { id: docSnap?.id, ...docSnap.data() } : null;
  }
  return null;
};

export const getGroups = async () => {
  const q = query(collection(db, "groups"), limit(12));
  const querySnapshot = await getDocs(q);
  const groups = querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
  return groups;
};

export const getGroupsLength = async () => {
  const collectionRef = collection(db, "groups");
  const snapshot = await getDocs(collectionRef);
  return snapshot.size;
};

export const getGroupById = async (id) => {
  const docRef = getDocument(id);
  const docSnap = await getDoc(docRef);
  const group = docSnap.data();
  return docSnap.exists ? { id: docSnap.id, ...group } : null;
};

export const createGroup = async (group) => {
  try {
    await addDoc(collection(db, "groups"), group);
    return group;
  } catch (e) {
    console.error(`Error while creating group ${e}`);
    return false;
  }
};

export const updateGroup = async (id, data) => {
  try {
    await updateDoc(getDocument(id), data);
  } catch (e) {
    console.error(`Error while updating group ${e}`);
    return false;
  }
};

export const deleteGroup = async (id) => {
  try {
    await deleteDoc(getDocument(id));
  } catch (e) {
    console.error(`Error while deleting group ${e}`);
    return false;
  }
};
