import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getEvents,
  createEvent,
  updateEvents,
  deleteEvent,
  getEventById,
} from "api/events.api";

export const getEventsAsync = createAsyncThunk("events/get-all", async () => {
  const data = await getEvents();
  return data;
});

export const getNextEventsAsync = createAsyncThunk(
  "events/get-all-next",
  async (last) => {
    const data = await getEvents(last);
    return data;
  },
);

export const getEventByIdAsync = createAsyncThunk(
  "event/get-by-id",
  async (id) => {
    const event = await getEventById(id);
    const response = await fetch(event.content);
    const text = await response.text();
    return { ...event, content: text };
  },
);

export const createEventAsync = createAsyncThunk(
  "events/create",
  async (Event) => {
    const events = await createEvent(Event);
    return events;
  },
);

export const updateEventAsync = createAsyncThunk(
  "event/update",
  async ({ id, data }) => {
    await updateEvents(id, data);
  },
);

export const deleteEventAsync = createAsyncThunk("event/delete", async (id) => {
  await deleteEvent(id);
});
