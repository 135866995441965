import React, { useState, useEffect } from "react";
// import { useTranslation } from "react-i18next";
// import { useParams } from "react-router-dom";
import { Animate } from "react-animate-mount";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Stepper from "components/stepper/Stepper";
import PresentationOverview from "components/presentations/PresentationOverview";
import PresentationContent from "components/presentations/PresentationContent";
import PresentationView from "components/presentations/PresentationView";
import usePresentationForm from "hooks/presentations/usePresentationForm";

const EditPresentation = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  //   const { t } = useTranslation();
  //   const { id } = useParams();
  //   const { current, updateGroup, createGroup } = useGroup({ id });
  //   const { users } = useUsers();
  const { presentationForm, FIELDS, values, setFieldValue, handleSubmit } =
    usePresentationForm();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const steps = [
    {
      id: 1,
      title: "Step 1",
      desc: "Presentation Overview", //t("groups.groupEdit.overview"),
      content: (
        <PresentationOverview
        //   overview={{
        //     title: values[FIELDS.TITLE],
        //     goal: values[FIELDS.GOAL],
        //     images: values[FIELDS.IMAGES],
        //   }}
        //   setFieldValue={setFieldValue}
        />
      ),
      icon: "",
    },
    {
      id: 2,
      title: "Step 3",
      desc: "Presentation Content", //t("groups.groupEdit.members"),
      content: (
        <PresentationContent
          slides={values[FIELDS.SLIDES]}
          setFieldValue={(value) => setFieldValue(FIELDS.SLIDES, value)}
        />
      ),
    },
    {
      id: 3,
      title: "Step 3",
      desc: "Presentation Review", //t("groups.groupEdit.review"),
      content: <PresentationView data={values[FIELDS.SLIDES]} />,
      //    <PresentationView
      //   //group={values}
      //    />,
    },
    {
      id: 5,
      title: "Step 3",
      desc: "Presentation publish", //t("groups.groupEdit.publish"),
      content: <div> Հրապարակված է </div>,
    },
  ];

  return (
    <div className="mt-10 flex flex-col justify-center">
      <Animate show={isMounted}>
        <Stepper
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </Animate>
      <div className="flex w-full justify-between mt-10">
        {activeStep < steps.length - 1 && (
          <div
            onClick={() => activeStep > 0 && setActiveStep(activeStep - 1)}
            className={`p-2 bg-opacity-50 z-50 flex items-center ${
              activeStep > 0 ? "opacity-100 cursor-pointer" : "opacity-0"
            }`}
          >
            <FaChevronLeft size={32} color="gray" />
          </div>
        )}
        <div className="w-full">
          {steps.map((step, index) => {
            return (
              <Animate show={index === activeStep} key={index}>
                {step.content}
              </Animate>
            );
          })}
        </div>
        {activeStep <= 3 && (
          <div
            onClick={() => {
              setActiveStep(activeStep + 1);
              //   if (activeStep === 3) {
              //     setActiveStep(activeStep + 1);
              //     handleSubmit();
              //   } else {
              //     setActiveStep(activeStep + 1);
              //   }
            }}
            className={`p-2 bg-opacity-50 cursor-pointer flex justify-end items-center ${
              steps.length !== activeStep ? "opacity-100" : "opacity-0"
            }`}
          >
            <FaChevronRight size={32} color="gray" />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditPresentation;
