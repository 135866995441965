import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InfiniteScrollTrigger from "components/infinite-scroll/InfiniteScrollTrigger";
import Card from "components/card/Card";
import { Button } from "@mui/material";
import { skeletonGenerator } from "components/infinite-scroll/helpers";
import ArticleSkeleton from "components/infinite-scroll/skeletons/ArticleSkeleton";

const ArticleList = ({
  articles,
  currentUserId,
  currentUserType,
  isLoadingNextPage,
  hasMoreItems,
  getNextPage,
  page,
  setPage,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="w-full h-full overflow-y-auto p-16"
      style={{
        background: "#e2e2e2",
      }}
    >
      <div className="m-auto">
        <div className="w-full grid justify-items-end">
          <Button
            variant="outlined"
            onClick={() => navigate("/articles/manage")}
            className="justify-items-end"
          >
            {t("articles.articleList.createNewArticle")}
          </Button>
        </div>
        <div className="p-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
          {" "}
          {articles.map(({ title, images, description, id, author, category }) => {
            return (
              <Card
                key={id}
                title={title}
                image={images?.cover || images?.poster}
                category={category}
                path={`/articles/${id}`}
                description={description}
                primaryButtonText={t("articles.articleList.card.view")}
                secondaryButtonText={t("articles.articleList.card.edit")}
                onSecondaryButtonClick={() =>
                  navigate(`/articles/manage/${id}`)
                }
                onPrimaryButtonClick={() => navigate(`/articles/${id}`)}
                showSecondaryButton={
                  author?.id === currentUserId ||
                  currentUserType === "admin" ||
                  currentUserType === "manager" ||
                  currentUserType === "editor"
                }
                author={author}
              />
            );
          })}
          {isLoadingNextPage && skeletonGenerator(<ArticleSkeleton />)}
          <InfiniteScrollTrigger
            isLoading={isLoadingNextPage}
            hasMoreItems={hasMoreItems}
            callback={getNextPage}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>
    </div>
  );
};

export default ArticleList;
