import React from "react";
import { useParams } from "react-router-dom";
import usePresentation from "hooks/presentations/usePresentation";
import PresentationView from "components/presentations/PresentationView";

const Presentation = ({ isEditable }) => {
  const { id } = useParams();
  const { current, isLoading } = usePresentation({ id });
  return (
    <div>
      {isLoading ? (
        <>Loading...</>
      ) : (
        <PresentationView data={current} isEditable={isEditable} />
      )}
    </div>
  );
};

export default Presentation;
