import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentGroup,
  selectIsLoading,
} from "redux-toolkit/groups/selectors";
import {
  getGroupByIdAsync,
  createGroupAsync,
  updateGroupAsync,
  deleteGroupAsync,
} from "redux-toolkit/groups/actions";

const useGroup = ({ id }) => {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentGroup);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    id && dispatch(getGroupByIdAsync(id));
  }, [dispatch, id]);

  const createGroup = useCallback(
    (newGroup) => {
      dispatch(createGroupAsync(newGroup));
    },
    [dispatch],
  );

  const updateGroup = useCallback(
    (id, data) => {
      dispatch(updateGroupAsync({ id, data }));
    },
    [dispatch],
  );

  const deleteGroup = useCallback(
    (id) => {
      dispatch(deleteGroupAsync(id));
    },
    [dispatch],
  );

  return {
    current,
    isLoading,
    createGroup,
    updateGroup,
    deleteGroup,
  };
};

export default useGroup;
