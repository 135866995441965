import { createAsyncThunk } from "@reduxjs/toolkit";
import { getArticlesLength } from "api/articles.api";
import { getPresentationsLength } from "api/presentations.api";
import { getGroupsLength } from "api/groups.api";

export const getArticlesLengthAsync = createAsyncThunk(
  "dashboard/get-article-length",
  async () => {
    const length = await getArticlesLength();
    return length;
  },
);

export const getPresentationsLengthAsync = createAsyncThunk(
  "dashboard/get-presentation-length",
  async () => {
    const length = await getPresentationsLength();
    return length;
  },
);

export const getGroupsLengthAsync = createAsyncThunk(
  "dashboard/get-group-length",
  async () => {
    const length = await getGroupsLength();
    return length;
  },
);
