import React from "react";
import ArticleList from "components/articles/ArticleList";
import Loading from "components/common/Loading";
import useArticles from "hooks/articles/useArticles";
import useAuth from "hooks/useAuth";

const Articles = () => {
  const { articles, isLoading, getNextPage, page, setPage, hasMoreItems } =
    useArticles();
  const { currentUser } = useAuth();
  return isLoading && articles.length === 0 ? (
    <Loading />
  ) : (
    <ArticleList
      articles={articles}
      currentUserId={currentUser?.id}
      currentUserType={currentUser?.type}
      isLoadingNextPage={isLoading}
      hasMoreItems={hasMoreItems}
      getNextPage={getNextPage}
      page={page}
      setPage={setPage}
    />
  );
};

export default Articles;
