import { useFormik } from "formik";
import { presentationFormSchema } from "libs/validation/schemas";

const FIELDS = {
  TITLE: "title",
  DESCRIPTION: "description",
  // IMAGES: "images",
  SLIDES: "slides",
};

const usePresentationForm = (props) => {
  const { presentation, create, update, id } = props || {};
  const presentationForm = useFormik({
    initialValues: {
      [FIELDS.TITLE]: presentation?.title || "",
      // [FIELDS.IMAGES]: presentation.images || {},
      [FIELDS.DESCRIPTION]: presentation?.description || "",
      [FIELDS.SLIDES]: presentation?.slides || [],
    },
    enableReinitialize: true,
    validationSchema: presentationFormSchema,
    onSubmit: async (values) => {
      create({
        ...values,
        createdAt: Date.now(),
      });
      update(id, {
        ...values,
        updatedAt: Date.now(),
      });
    },
  });

  const { values, setFieldValue, handleSubmit, resetForm } = presentationForm;

  return {
    presentationForm,
    FIELDS,
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    errors: presentationForm.errors,
  };
};

export default usePresentationForm;
