const useEditQuiz = ({ currentQuiz, setCurrentQuiz }) => {
  const newQuestions = currentQuiz.questions?.map((item) => ({
    ...item,
    answers: item.answers.map((item) => item),
    question: item.question,
    correctAnswer: Array.isArray(item.correctAnswer)
      ? item.correctAnswer?.map((item) => item)
      : item.correctAnswer,
  }));
  const handleCorrectAnswersChange = (
    isCorrect,
    questionIndex,
    answerIndex,
  ) => {
    if (isCorrect) {
      if (newQuestions[questionIndex].answerSelectionType === "multiple") {
        const currentIndex = newQuestions[questionIndex].correctAnswer.indexOf(
          answerIndex + 1,
        );
        newQuestions[questionIndex].correctAnswer.splice(currentIndex, 1);
        setCurrentQuiz({ ...currentQuiz, questions: newQuestions });
      } else {
        newQuestions[questionIndex].correctAnswer = "";
        setCurrentQuiz({ ...currentQuiz, questions: newQuestions });
      }
    } else {
      if (newQuestions[questionIndex].answerSelectionType === "multiple") {
        newQuestions[questionIndex].correctAnswer.push(answerIndex + 1);
        setCurrentQuiz({ ...currentQuiz, questions: newQuestions });
      } else {
        newQuestions[questionIndex].correctAnswer = (
          answerIndex + 1
        ).toString();
        setCurrentQuiz({ ...currentQuiz, questions: newQuestions });
      }
    }
  };

  return {
    newQuestions,
    handleCorrectAnswersChange,
  };
};

export default useEditQuiz;
