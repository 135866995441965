export const groupRoles = {
  lead: {
    label: "groups.roles.lead",
    role: "lead",
  },
  coordinator: {
    label: "groups.roles.coordinator",
    role: "coordinator",
  },
  member: {
    label: "groups.roles.member",
    role: "member",
  },
};
