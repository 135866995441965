import React from "react";
import PropTypes from "prop-types";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Button, TextField } from "@mui/material";
import useEditQuiz from "components/quiz/useEditQuiz";

const QuizForm = (props) => {
  const { currentQuiz, setCurrentQuiz } = props;
  const { t } = useTranslation();
  const { newQuestions, handleCorrectAnswersChange } = useEditQuiz({
    currentQuiz,
    setCurrentQuiz,
  });
  return (
    <div className="w-full flex items-center justify-center bg-gray-100 group">
      <div className="h-full w-full bg-gray-100 p-4">
        <div className="flex justify-between border-b pb-4 mb-4">
          <span size="16" font="normal">
            {t("lessons.quiz.questions")}
          </span>
        </div>
        {currentQuiz.questions.map((item, questionIndex) => {
          return (
            <div key={questionIndex} className="flex flex-col mt-4">
              <div className="flex">
                <div className="w-full">
                  <TextField
                    fullWidth
                    defaultValue={item.question}
                    onChange={(e) => {
                      newQuestions[questionIndex].question = e.target.value;
                      setCurrentQuiz({
                        ...currentQuiz,
                        questions: newQuestions,
                      });
                    }}
                    placeholder={`հարց ${questionIndex + 1}`}
                  />
                </div>
                <div className="opacity-0 group-hover:opacity-100">
                  <Button
                    onClick={() => {
                      newQuestions.splice(questionIndex, 1);
                      setCurrentQuiz({
                        ...currentQuiz,
                        questions: newQuestions,
                      });
                    }}
                  >
                    <MdDelete color="red" size={20} />
                  </Button>
                </div>
              </div>
              {/* <select
                options={selectSettings.options}
                placeholder="Selection type"
                className="mt-4 justify-self-end w-36"
                styles={selectSettings.styles}
                onChange={(value) => {
                  newQuestions[questionIndex].answerSelectionType = value;
                  value === "multiple"
                    ? (newQuestions[questionIndex].correctAnswer = [])
                    : (newQuestions[questionIndex].correctAnswer = "");
                  setFieldValue(FIELDS.QUESTIONS, newQuestions);
                }}
                value={
                  values[FIELDS.QUESTIONS][questionIndex].answerSelectionType
                }
              /> */}
              {currentQuiz.questions[questionIndex].answers.map(
                (item, answerIndex) => {
                  const handleOnChange = (value) => {
                    newQuestions[questionIndex].answers[answerIndex] = value;
                    setCurrentQuiz({ ...currentQuiz, questions: newQuestions });
                  };
                  const isArray = Array.isArray(
                    newQuestions[questionIndex].correctAnswer,
                  );
                  const isCorrect = isArray
                    ? newQuestions[questionIndex].correctAnswer.includes(
                        answerIndex + 1,
                      )
                    : answerIndex ===
                      parseInt(newQuestions[questionIndex].correctAnswer) - 1;
                  return (
                    <div
                      key={answerIndex}
                      className="flex justify-between items-center mt-6 ml-16"
                    >
                      <div className="flex mr-2">
                        <span
                          className={`${
                            isCorrect ? "text-green-500" : "text-red-500"
                          } text-sm`}
                        >
                          {isCorrect
                            ? t("lessons.quiz.correct")
                            : t("lessons.quiz.wrong")}
                        </span>
                        <input
                          className="ml-2"
                          type="checkbox"
                          id="scales"
                          name="scales"
                          checked={isCorrect}
                          onChange={() =>
                            handleCorrectAnswersChange(
                              isCorrect,
                              questionIndex,
                              answerIndex,
                            )
                          }
                        />
                      </div>
                      <div className="w-full">
                        <TextField
                          fullWidth
                          value={
                            currentQuiz.questions[questionIndex].answers[
                              answerIndex
                            ]
                          }
                          onChange={(e) => {
                            handleOnChange(e.target.value);
                          }}
                          placeholder={`պատասխան ${answerIndex + 1}`}
                        />
                      </div>
                      <div className="opacity-0 group-hover:opacity-100">
                        <Button
                          onClick={() => {
                            newQuestions[questionIndex].answers.splice(
                              answerIndex,
                              1,
                            );
                            if (isArray)
                              newQuestions[questionIndex].correctAnswer = [];
                            setCurrentQuiz({
                              ...currentQuiz,
                              questions: newQuestions,
                            });
                          }}
                        >
                          <MdDelete color="red" size={20} />
                        </Button>
                      </div>
                    </div>
                  );
                },
              )}
              <div className="flex justify-center mt-4">
                <Button
                  onClick={() => {
                    newQuestions[questionIndex].answers.push("");
                    setCurrentQuiz({ ...currentQuiz, questions: newQuestions });
                  }}
                >
                  <IoMdAdd size={20} />
                  {t("lessons.quiz.addAnswer")}
                </Button>
              </div>
            </div>
          );
        })}
        <div className="flex justify-center mt-8">
          <Button
            onClick={() => {
              newQuestions.push({
                answers: [],
                questionType: "text",
                question: "",
                point: "1",
                answerSelectionType: "single",
              });
              setCurrentQuiz({ ...currentQuiz, questions: newQuestions });
            }}
          >
            <IoMdAdd size={20} />
            {t("lessons.quiz.addQuestion")}
          </Button>
        </div>
      </div>
    </div>
  );
};

QuizForm.propTypes = {
  setCurrentQuiz: PropTypes.func.isRequired,
};

export default QuizForm;
