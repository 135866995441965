import React from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const VideoInput = ({ video, errors, setValue }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-8 items-center w-full px-20">
      <div className="w-1/2 flex flex-col gap-2">
        <TextField
          size="small"
          value={video || ""}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
          label={t("lessons.videoInput")}
          error={Boolean(errors?.description)}
          helperText={t(errors?.description)}
          multiline
          rows={10}
        />
      </div>
    </div>
  );
};

export default VideoInput;
