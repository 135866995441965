import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPresentations,
  getPresentationById,
  createPresentation,
  updatePresentation,
  deletePresentation,
} from "api/presentations.api";

export const getPresentationsAsync = createAsyncThunk(
  "presentations/get-all",
  async () => {
    const presentations = await getPresentations();
    return presentations;
  },
);

export const getPresentationByIdAsync = createAsyncThunk(
  "presentations/get-by-id",
  async (id) => {
    const presentation = await getPresentationById(id);
    return presentation;
  },
);

export const createPresentationAsync = createAsyncThunk(
  "presentations/create",
  async (presentation) => {
    const newPresentation = await createPresentation(presentation);
    return newPresentation;
  },
);

export const updatePresentationAsync = createAsyncThunk(
  "presentations/update",
  async ({ id, data }) => {
    await updatePresentation(id, data);
  },
);

export const deletePresentationAsync = createAsyncThunk(
  "presentations/delete",
  async (id) => {
    await deletePresentation(id);
  },
);
