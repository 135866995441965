import React from "react";
import Loading from "components/common/Loading";
import useEvents from "hooks/events/useEvents";
import useAuth from "hooks/useAuth";
import EventsList from "components/events/EventsList";

const Events = () => {
  const { events, isLoading, getNextPage, page, setPage, hasMoreItems } =
    useEvents();
  const { currentUser } = useAuth();
  return isLoading && events.length === 0 ? (
    <Loading />
  ) : (
    <EventsList
      currentUser={currentUser}
      events={events}
      hasMoreItems={hasMoreItems}
      getNextPage={getNextPage}
      page={page}
      setPage={setPage}
      isLoadingNextPage={isLoading}
    />
  );
};

export default Events;
