import React from "react";
import shortid from "shortid";
// import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdOutlineDragHandle } from "react-icons/md";

// import { NOOP } from 'libs/utils';

const DraggableList = ({
  items,
  handleStateChange,
  template,
  deleteModule,
  deleteReusableLesson,
  ...rest
}) => {
  // Reorder list state on drag-end
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (
      destination &&
      (destination.droppableId !== source.droppableId ||
        destination.index !== source.index)
    ) {
      const newItems = [...items];
      const draggableContent = items.find((item) => item.id === draggableId);

      newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, draggableContent);

      handleStateChange(newItems);
    }
  };
  // #endregion Event handlers
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`items-${shortid.generate()}`}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="flex flex-col gap-4"
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    className="flex"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <div
                      {...provided.dragHandleProps}
                      className="self-start max-w-4 mt-2 mx-2"
                    >
                      <MdOutlineDragHandle color="gray" size={30} />
                    </div>
                    <div className="w-full" {...rest}>
                      {item.element}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

// DraggableList.propTypes = {
//   list: PropTypes.array,
//   handleStateChange: PropTypes.func,
//   template: PropTypes.node.isRequired,
// };

// DraggableList.defaultProps = {
//   list: [],
//   handleStateChange: NOOP,
// };

export default DraggableList;
