const useEditTopics = ({ currentTopics }) => {
  const newTopics =
    currentTopics?.topics?.map((item) => ({
      ...item,
      speakers: item.speakers.map((item) => item),
      topic: item.topic,
    })) || [];

  return {
    newTopics,
  };
};

export default useEditTopics;
