import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getGroups,
  createGroup,
  updateGroup,
  deleteGroup,
  getGroupById,
} from "api/groups.api";

export const getGroupsAsync = createAsyncThunk("group/get-all", async () => {
  const groups = await getGroups();
  return groups;
});

export const getGroupByIdAsync = createAsyncThunk(
  "group/get-by-id",
  async (id) => {
    const group = await getGroupById(id);
    return group;
  },
);

export const createGroupAsync = createAsyncThunk(
  "group/create",
  async (group) => {
    const newGroup = await createGroup(group);
    return newGroup;
  },
);

export const updateGroupAsync = createAsyncThunk(
  "group/update",
  async ({ id, data }) => {
    await updateGroup(id, data);
  },
);

export const deleteGroupAsync = createAsyncThunk("group/delete", async (id) => {
  await deleteGroup(id);
});
