import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  limit,
  addDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "config/firebase";

const getDocument = (id) => {
  return doc(db, "presentations", id);
};

export const getPresentations = async () => {
  const q = query(collection(db, "presentations"), limit(12));
  const querySnapshot = await getDocs(q);
  const groups = querySnapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
  return groups;
};

export const getPresentationsLength = async () => {
  const collectionRef = collection(db, "presentations");
  const snapshot = await getDocs(collectionRef);
  return snapshot.size;
};

export const getPresentationById = async (id) => {
  const docRef = getDocument(id);
  const docSnap = await getDoc(docRef);
  const group = docSnap.data();
  return docSnap.exists ? { id: docSnap.id, ...group } : null;
};

export const createPresentation = async (presentations) => {
  try {
    await addDoc(collection(db, "presentations"), presentations);
    return presentations;
  } catch (e) {
    console.error(`Error while creating presentations ${e}`);
    return false;
  }
};

export const updatePresentation = async (id, data) => {
  try {
    await updateDoc(getDocument(id), data);
  } catch (e) {
    console.error(`Error while updating presentations ${e}`);
    return false;
  }
};

export const deletePresentation = async (id) => {
  try {
    await deleteDoc(getDocument(id));
  } catch (e) {
    console.error(`Error while deleting presentations ${e}`);
    return false;
  }
};
