import PropTypes from "prop-types";
import { NOOP } from "libs/utils";

const Suggestion = ({ suggestion, onSelect }) => {
  const {
    place_id,
    structured_formatting: { main_text, secondary_text },
  } = suggestion;

  return (
    <li
      className="cursor-pointer px-2"
      key={place_id}
      onClick={() => onSelect(suggestion)}
    >
      <span font="normal" color="orange-800" size="16">
        {main_text}&nbsp;
      </span>
      <span size="14">{secondary_text}</span>
    </li>
  );
};

Suggestion.propTypes = {
  onSelect: PropTypes.func,
};

Suggestion.defaultProps = {
  onSelect: NOOP,
};

export default Suggestion;
