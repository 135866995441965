import { useTranslation } from "react-i18next";

import { TextField } from "@mui/material";
import MapsModal from "./MapsModal";
import Suggestion from "./Suggestion";
import useGoogleMaps from "../useGoogleMaps";

const LocationInput = ({
  location,
  setLocation,
  setMapPosition,
  mapPosition,
}) => {
  const { t } = useTranslation();
  const {
    status,
    data,
    isOpen,
    setIsOpen,
    selected,
    ref,
    handleInput,
    handleSelect,
    handleOnMapClick,
  } = useGoogleMaps(setLocation, setMapPosition);

  return (
    <>
      <div className="bg-white flex w-full" ref={ref}>
        <TextField
          fullWidth
          placeholder={t("map.address")}
          value={location}
          onChange={(e) => handleInput(e.target.value)}
        />
        {status === "OK" && (
          <ul className="bg-white z-10 w-full px-4 py-2 absolute">
            {data.map((suggestion, index) => (
              <Suggestion
                key={data.place_id || index}
                suggestion={suggestion}
                onSelect={handleSelect}
              />
            ))}
          </ul>
        )}
      </div>
      <MapsModal
        selectedLocation={selected || mapPosition}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onMapClick={handleOnMapClick}
      />
    </>
  );
};
export default LocationInput;
