import { createSlice } from "@reduxjs/toolkit";
import {
  getArticlesLengthAsync,
  getPresentationsLengthAsync,
  getGroupsLengthAsync,
} from "./actions";

const initialState = {
  isLoading: true,
  articlesLength: 0,
  presentationsLength: 0,
  groupsLength: 0,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getArticlesLengthAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getArticlesLengthAsync.fulfilled, (state, action) => {
        state.articlesLength = action.payload;
        state.isLoading = false;
      })
      .addCase(getArticlesLengthAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.articlesLength = 0;
      })
      .addCase(getPresentationsLengthAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPresentationsLengthAsync.fulfilled, (state, action) => {
        state.presentationsLength = action.payload;
        state.isLoading = false;
      })
      .addCase(getPresentationsLengthAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.presentationsLength = 0;
      })
      .addCase(getGroupsLengthAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getGroupsLengthAsync.fulfilled, (state, action) => {
        state.groupsLength = action.payload;
        state.isLoading = false;
      })
      .addCase(getGroupsLengthAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.groupsLength = 0;
      });
  },
});

export default dashboardSlice.reducer;
